<script>
import { reactive, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import vuexStore from "../../../store";
import { Loader } from "@googlemaps/js-api-loader";
// import { checkEmailExists } from "../../../services/auth";

export default {
  beforeRouteEnter(to, from, next) {
    const state = vuexStore.state.cases;
    const { purchase_case, sell_case, property } = state;
    let isValid = true;
    if (property === "both") {
      if (!purchase_case.amount || !sell_case.amount) {
        isValid = false;
      }
    } else if (
      property === "sale" ? !sell_case.amount : !purchase_case.amount
    ) {
      isValid = false;
    }
    if (!isValid || !property) {
      next({ name: 2 });
    } else {
      next();
    }
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const email = ref(null);
    const personal = reactive({
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      country: "UK",
      phone_country: "UK",
      street_address: "",
      city: "",
      message: "",
      postcode: '',
    });
    // const mapDiv = ref(null);
    let map = ref(null);
    let google = ref(null);  
    const searchInput = ref(null);
    const loader = new Loader({
      apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      libraries: ["places"]
    });

    onMounted(async () => {
      await loader.load();

      // map.value = new window.google.maps.Map(mapDiv.value, {
      //   // center: JSON.parse(personal.address.coords),
      //   streetViewControl: false,
      //   mapTypeControl: false,
      //   zoom: 15
      // });

      const isSupported = "navigator" in window && "geolocation" in navigator;

      if (isSupported)
        navigator.geolocation.getCurrentPosition(position => {
          personal.address.coords = JSON.stringify({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          });
          map.value.setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          });
          map.value.setZoom(15);
        });
        
       
      google.value = window.google;
      const searchBox = new google.value.maps.places.SearchBox(
        searchInput.value
      );
   
      let markers = [];
      searchBox.addListener("places_changed", () => {
        const places = searchBox.getPlaces();
        if (places.length == 0) {
          return;
        }
        // Clear out the old markers.
        markers.forEach(marker => {
          marker.setMap(null);
        });
        markers = [];
        // For each place, get the icon, name and location.
        // const bounds = new google.value.maps.LatLngBounds();
        places.forEach(place => {
          if (!place.geometry || !place.geometry.location) {
            console.log("Returned place contains no geometry");
            return;
          }

          // Create a marker for each place.
          let marker = new google.value.maps.Marker({
            map: map.value,
            title: place.name,
            position: place.geometry.location,
            draggable: true
          });

          geocoderToAdress(marker.position);

          google.value.maps.event.addListener(marker, "dragend", function(
            marker
          ) {
            var latLng = marker.latLng;
            personal.address.coords = JSON.stringify({
              lat: latLng.lat(),
              lng: latLng.lng()
            });
            geocoderToAdress(latLng);
          });

          markers.push(marker);

          // if (place.geometry.viewport) {
          //   // Only geocodes have viewport.
          //   bounds.union(place.geometry.viewport);
          // } else {
          //   bounds.extend(place.geometry.location);
          // }
        });

        // map.value.fitBounds(bounds);
        // map.value.setCenter(markers[0].getPosition());
        // map.value.setZoom(15);
      });
    });
    const geocoderToAdress = latLng => {
      var geocoder = new google.value.maps.Geocoder();
      geocoder.geocode(
        {
          latLng
        },
        function(results, status) {
          if (status == google.value.maps.GeocoderStatus.OK) {
            const fullAdress = results.find(i =>
              i.types.includes("street_address")
            );
            if (fullAdress?.formatted_address) {
              personal.street_address = fullAdress.formatted_address;
              console.log(fullAdress.address_components)
              for (let adress of fullAdress.address_components) {
                if (adress.types.includes("postal_code")) {
                  personal.postcode = adress.long_name;
                }
                if (adress.types.includes("country")) {
                  personal.country = adress.long_name;
                }
                if (adress.types.includes(`administrative_area_level_2`)) {
                  personal.city = adress.long_name;
                } else if (
                  adress.types.includes(`administrative_area_level_1`)
                ) {
                  personal.state = adress.long_name;
                }
              }
            }
          }
        }
      );
    };
    // const {
    //   sell_case,
    //   purchase_case ,property} = store.state.cases;
    // const sell = { ...sell_case };
    // const purchase = { ...purchase_case };

    // if(property == 'sale'){
        
    //     personal.postcode = sell.address.postcode;
    //     personal.street_address = sell.address.street_address;
    //     personal.city= sell.address.city;
   
   
    //     if(sell.address.country.toLowerCase().trim() == 'united kingdom' || sell.address.country.toLowerCase().trim() == 'uk'){
    //       personal.country= "UK";
      
       
    //     }else if(sell.address.country.toLowerCase().trim() == 'canada' || sell.address.country.toLowerCase().trim() == 'ca'){
    //       personal.country= "CA";
    //     }  
        
    // }else if(property == 'purchase'){
          
    //     personal.postcode = purchase.address.postcode;
    //     personal.street_address = purchase.address.street_address;
    //     personal.city= purchase.address.city;

    //     if(purchase.address.country.toLowerCase().trim() == 'United Kingdom' || purchase.address.country.toLowerCase().trim() == 'UK'){
    //       personal.country= "UK";
    //     }
    //     else if(purchase.address.country.toLowerCase().trim() == 'canada' || purchase.address.country.toLowerCase().trim() == 'ca'){
    //       personal.country= 'CA';
    //     }  
    // }else if(property == 'both'){
           
    //     personal.postcode = purchase.address.postcode;
    //     personal.street_address = purchase.address.street_address;
    //     personal.city= purchase.address.city;

    //     if(sell.address.country.toLowerCase().trim() == 'United Kingdom' || sell.address.country.toLowerCase().trim() == 'UK'){
    //       personal.country= "UK";
    //     }
    //     else if(sell.address.country.toLowerCase().trim() == 'canada' || sell.address.country.toLowerCase().trim() == 'ca'){
    //       personal.country= 'CA';
    //     }  

    // }


    const handleSubmit = () => {
      store.dispatch("cases/updatePersonal", { ...personal });
      router.push({ name: "quote" });
    };

    // const verifyEmail = async () => {
    //   try {
    //     const re =
    //       /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //     if (!re.test(personal.email)) {
    //       email.value.setCustomValidity("Invalid email address");
    //       return;
    //     } else {
    //       email.value.setCustomValidity("");
    //     }
    //     await checkEmailExists(personal.email);
    //     email.value.setCustomValidity("");
    //   } catch (e) {
    //     email.value.setCustomValidity("Email already taken");
    //   }
    // };

    return {
      personal,
      handleSubmit,
      email,
      searchInput,
      // mapDiv
      // verifyEmail,
    };
  },
};
</script>

<template>
  <div class="grid gap-y-4 max-w-3xl mx-auto w-full pb-8">
    <div>
      <h2 class="text-3xl font-extrabold text-gray-900 text-center">
        Personal information
      </h2>
    </div>
    <div>
      <div class="mt-5 md:mt-0 md:col-span-2">
        <form @submit.prevent="handleSubmit" class="space-y-8">
          <div class="shadow sm:rounded-md sm:overflow-hidden">
            <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
              <div>
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Personal information
                </h3>
                <p class="mt-1 text-sm text-gray-500">
                  Use a permanent address where you can receive mail.
                </p>
              </div>
              <div class="grid grid-cols-6 gap-6">
                <div class="col-span-6 sm:col-span-3">
                  <label
                    for="first_name"
                    class="block text-sm font-medium text-gray-700"
                    >First name</label
                  >
                  <input
                    required
                    v-model="personal.first_name"
                    type="text"
                    name="first_name"
                    id="first_name"
                    autocomplete="given-name"
                    class="
                      mt-1
                      block
                      w-full
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      py-2
                      px-3
                      focus:outline-none
                      focus:ring-blue-500
                      focus:border-blue-500
                      sm:text-sm
                    "
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label
                    for="last_name"
                    class="block text-sm font-medium text-gray-700"
                    >Last name</label
                  >
                  <input
                    required
                    type="text"
                    v-model="personal.last_name"
                    name="last_name"
                    id="last_name"
                    autocomplete="family-name"
                    class="
                      mt-1
                      block
                      w-full
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      py-2
                      px-3
                      focus:outline-none
                      focus:ring-blue-500
                      focus:border-blue-500
                      sm:text-sm
                    "
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label
                    for="email_address"
                    class="block text-sm font-medium text-gray-700"
                    >Email address</label
                  >
                  <!-- @input="verifyEmail" -->
                  <input
                    required
                    ref="email"
                    v-model="personal.email"
                    type="email"
                    name="email_address"
                    id="email_address"
                    autocomplete="email"
                    class="
                      mt-1
                      block
                      w-full
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      py-2
                      px-3
                      focus:outline-none
                      focus:ring-blue-500
                      focus:border-blue-500
                      sm:text-sm
                    "
                  />
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <div>
                    <label
                      for="phone_number"
                      class="block text-sm font-medium text-gray-700"
                      >Phone Number</label
                    >
                    <div class="mt-1 relative rounded-md shadow-sm">
                      <div class="absolute inset-y-0 left-0 flex items-center">
                        <label for="country" class="sr-only">Country</label>
                        <select
                          required
                          v-model="personal.phone_country"
                          id="country"
                          name="country"
                          class="
                            focus:ring-blue-500 focus:border-blue-500
                            h-full
                            py-0
                            pl-3
                            pr-7
                            border-transparent
                            bg-transparent
                            text-gray-500
                            sm:text-sm
                            rounded-md
                          "
                        >
                          <option>UK</option>
                          <option>CA</option>
                          <option>EU</option>
                        </select>
                      </div>
                      <input
                        type="tel"
                        required
                        v-model="personal.phone_number"
                        name="phone_number"
                        id="phone_number"
                        class="
                          focus:ring-blue-500 focus:border-blue-500
                          block
                          w-full
                          pl-16
                          sm:text-sm
                          border-gray-300
                          rounded-md
                        "
                        placeholder="(XXX) XXX-XXXX"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <label
                    for="country"
                    class="block text-sm font-medium text-gray-700"
                    >Country / Region</label
                  >
                  <select
                    id="country"
                    required
                    name="country"
                    v-model="personal.country"
                    autocomplete="country"
                    class="
                      mt-1
                      block
                      w-full
                      bg-white
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      py-2
                      px-3
                      focus:outline-none
                      focus:ring-blue-500
                      focus:border-blue-500
                      sm:text-sm
                    "
                  >
                    <option value="UK">United Kingdom</option>
                    <option value="CA">Canada</option>
                  </select>
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label
                    for="street_address"
                    class="block text-sm font-medium text-gray-700"
                    >Street address</label
                  >
                  <input
                    required
                    ref="searchInput"
                    onkeydown="return (event.keyCode!=13);"
                    type="text"
                    class="
                      mt-1
                      block
                      w-full
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      py-2
                      px-3
                      focus:outline-none
                      focus:ring-blue-500
                      focus:border-blue-500
                      sm:text-sm
                    "
                    placeholder="Street Address"
                  />
                  <input type="hidden"
                    v-model="personal.street_address" 
                    name="street_address"
                    id="street_address"
                    autocomplete="street-address"
                    >
                  
                </div>

                <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label
                    for="city"
                    class="block text-sm font-medium text-gray-700"
                    >City</label
                  >
                  <input
                    required
                    type="text"
                    name="city"
                    v-model="personal.city"
                    id="city"
                    class="
                      mt-1
                      block
                      w-full
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      py-2
                      px-3
                      focus:outline-none
                      focus:ring-blue-500
                      focus:border-blue-500
                      sm:text-sm
                    "
                  />
                </div>
                <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label
                    for="state"
                    class="block text-sm font-medium text-gray-700"
                    >State / Province</label
                  >
                  <input
                    type="text"
                    name="state"
                    id="state"
                    v-model="personal.state"
                    class="
                      mt-1
                      block
                      w-full
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      py-2
                      px-3
                      focus:outline-none
                      focus:ring-blue-500
                      focus:border-blue-500
                      sm:text-sm
                    "
                  />
                </div>

                <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label
                    for="postal_code"
                    class="block text-sm font-medium text-gray-700"
                    >Postcode</label
                  >
                  <input
                    required
                    v-model="personal.postcode"
                    type="text"
                    name="postal_code"
                    id="postal_code"
                    autocomplete="postal-code"
                    class="
                      mt-1
                      block
                      w-full
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      py-2
                      px-3
                      focus:outline-none
                      focus:ring-blue-500
                      focus:border-blue-500
                      sm:text-sm
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="shadow sm:rounded-md sm:overflow-hidden">
            <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
              <div>
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Additional information
                </h3>
                <p class="mt-1 text-sm text-gray-500">
                  Add any additional information below.
                </p>
              </div>
              <div class="sm:col-span-6">
                <label
                  for="message"
                  class="block text-sm font-medium text-gray-700"
                >
                  Message
                </label>
                <div class="mt-1">
                  <textarea
                    id="message"
                    name="message"
                    v-model="personal.message"
                    rows="3"
                    class="
                      shadow-sm
                      focus:ring-blue-500 focus:border-blue-500
                      block
                      w-full
                      sm:text-sm
                      border-gray-300
                      rounded-md
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <button
            type="submit"
            class="
              inline-flex
              float-right
              w-full
              max-w-xs
              justify-center
              items-center
              px-4
              py-2
              border border-transparent
              text-base
              font-medium
              rounded-md
              shadow-sm
              text-white
              bg-light-blue-700
              hover:bg-light-blue-800
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:light-blue-500
            "
          >
            Save and get an instant quote
          </button>
        </form>
      </div>
    </div>
  </div>
</template>
